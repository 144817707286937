import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { KnAccountBillingInfoResponse, LinkAccountRequest } from "@/services/generated";
import Services from "@/services";

interface BillingInfo extends KnAccountBillingInfoResponse {
  id?: string;
}

type State = {
  info?: BillingInfo | null;
  loading: boolean;
  error: string | null;
};

const initialState: State = {
  info: null,
  loading: true,
  error: null,
};

export const getBillingInfo = createAsyncThunk(
  "settings/getBillingInfo",
  async (teamId: string, { rejectWithValue, dispatch }) => {
    try {
      return await Services.Billing.billingAccountInfo(teamId);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const createCustomerPortalUrl = createAsyncThunk(
  "billing/createCustomerPortalUrl",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Billing.createCustomerPortalUrl();
      if (!response.data || response.status !== 200) {
        return rejectWithValue(response.statusText);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const activateCode = createAsyncThunk(
  "billing/activateCode",
  async (body: LinkAccountRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Billing.activate(body);
      if (response.status !== 200) {
        return rejectWithValue(response.statusText);
      }
      return true;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setBillingInfo: (state, action) => {
      state.info = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBillingInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBillingInfo.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.info = payload.data;
        }
        state.loading = false;
      })
      .addCase(getBillingInfo.rejected, (state, { payload }) => {
        state.loading = false;
      });
  },
});

export const { setBillingInfo } = billingSlice.actions;

export default billingSlice.reducer;
